import type { IParamsFilter, IProduct, IProductPaginated, IVisualDescription } from '@/interfaces/product'
import { get, post, postFormData } from './ajaxutils'

export default {
  getProducts(params: IParamsFilter): Promise<IProductPaginated> {
    return get('/api/products', { params: JSON.stringify(params) })
  },
  saveProduct(data: IProduct): Promise<IProduct> {
    return post('/api/product/save', { ...data })
  },
  addVisualDescription(
    product_id: number | null,
    visual_description: File | null,
    caption: string,
    caption_en: string
  ): Promise<Array<IVisualDescription>> {
    return postFormData('/api/product/visual_description/save', {
      product_id,
      visual_description,
      caption,
      caption_en,
    })
  },
  removeVisualDescription(visual_description_id: number | null): Promise<Array<IVisualDescription>> {
    return post('/api/product/visual_description/delete', { visual_description_id })
  },
}
