import type {
  IEntryDocument,
  IEntryDocumentPaginated,
  IParamsFilter,
  IProposal,
  IReleaseSample,
  ISaveEntryDocument,
  ISaveHistory,
} from '@/interfaces/entry-document'
import type { IHistory } from '@/interfaces/history'
import type { IFile, ISample } from '@/interfaces/proposal'
import type { ITransition } from '@/interfaces/transition'
import { get, post, postFormData } from './ajaxutils'

export default {
  getEntryDocuments(params: IParamsFilter): Promise<IEntryDocumentPaginated> {
    return get('/api/entry_documents', { params: JSON.stringify(params) })
  },
  createEntryDocument(proposal_id: number | null): Promise<void> {
    return post('/api/entry_document/create', { proposal_id })
  },
  saveEntryDocument(data: ISaveEntryDocument): Promise<IEntryDocument> {
    return post('/api/entry_document/save', { ...data })
  },
  saveSample(data: ISample): Promise<Array<ISample>> {
    return post('/api/entry_document/sample/save', { ...data })
  },
  getProposals(): Promise<Array<IProposal>> {
    return get('/api/entry_document/proposals')
  },
  generateNewRevision(entry_document_id: number | null): Promise<void> {
    return post('/api/entry_document/revision', { entry_document_id })
  },
  sendToCommercial(entry_document_id: number | null): Promise<IEntryDocument> {
    return post('/api/entry_document/send_to_commercial', { entry_document_id })
  },
  sendToTechnicalArea(entry_document_id: number | null): Promise<IEntryDocument> {
    return post('/api/entry_document/send_to_technical_area', { entry_document_id })
  },
  getHistory(entry_document_id: number): Promise<Array<IHistory>> {
    return get('/api/entry_document/history', { entry_document_id })
  },
  saveEntryDocumentHistory(data: ISaveHistory): Promise<void> {
    return post('/api/entry_document/history/save', { ...data })
  },
  getTransitions(entry_document_id: number | null): Promise<Array<ITransition>> {
    return get('/api/entry_document/transitions', { entry_document_id })
  },
  addFile(sample_id: number | null, file: File | null): Promise<{ files: Array<IFile>; samples: Array<ISample> }> {
    return postFormData('/api/entry_document/sample/file/save', {
      sample_id,
      file,
    })
  },
  removeFile(sample_file_id: number | null): Promise<{ files: Array<IFile>; samples: Array<ISample> }> {
    return post('/api/entry_document/sample/file/delete', { sample_file_id })
  },
  releaseSample(
    sample_id: number | null,
    areas: string
  ): Promise<{ release_list: Array<IReleaseSample>; samples: Array<ISample> }> {
    return post('/api/entry_document/sample/release', { sample_id, areas })
  },
  notifyTechnicalArea(entry_document_id: number | null, areas: string): Promise<void> {
    return post('/api/entry_document/notify_technical_area', { entry_document_id, areas })
  },
}
