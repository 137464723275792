<template>
  <v-snackbar v-model="opened" :timeout="timeout" :color="color">
    {{ message }}

    <template #actions>
      <v-btn variant="text" @click="close"> {{ $t('toast.btnClose') }} </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useToastStore } from '@/stores/toast'
import { computed } from 'vue'

const toast = useToastStore()

const opened = computed({
  get() {
    return toast.opened
  },
  set() {
    close()
  },
})

const message = computed(() => toast.message)

const timeout = computed(() => toast.timeout || 6000)

const color = computed(() => toast.color)

function close(): void {
  toast.close()
}
</script>
