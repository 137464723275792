import type { EnumStudyType } from '@/enums/study-plan'
import type { IHistory } from '@/interfaces/history'
import type { ISample } from '@/interfaces/proposal'
import type {
  IAddControlledCondition,
  IAddPop,
  IAddReferenceItem,
  IAddSample,
  IAddStudyPerformer,
  IAddStudyPlan,
  IControlledCondition,
  ICustomerPdf,
  IEditCosmeticForm,
  IEntryDocument,
  IParamsFilter,
  IPop,
  IPopResponse,
  IReferenceItem,
  ISampleResponse,
  ISaveHistory,
  ISavePop,
  IStudyPerformer,
  IStudyPlan,
  IStudyPlanPaginated,
  IStudyResponse,
} from '@/interfaces/study-plan'
import type { ITransition } from '@/interfaces/transition'
import { get, post, postFormData } from './ajaxutils'

export default {
  getStudyPlans(params: IParamsFilter): Promise<IStudyPlanPaginated> {
    return get('/api/study_plans', { params: JSON.stringify(params) })
  },
  createStudyPlan(data: IAddStudyPlan): Promise<void> {
    return post('/api/study_plan/create', { ...data })
  },
  createStudyPlanPatchTest(): Promise<void> {
    return post('/api/study_plan/patch_test/create')
  },
  saveStudyPlan(data: IStudyPlan): Promise<IStudyPlan> {
    return post('/api/study_plan/save', { ...data })
  },
  getEntryDocuments(): Promise<Array<IEntryDocument>> {
    return get('/api/study_plan/entry_documents')
  },
  getEntryDocumentsGlp(): Promise<Array<IEntryDocument>> {
    return get('/api/study_plan/entry_documents_glp')
  },
  generateNewRevision(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/revision', { study_plan_id })
  },
  sendToQualityManager(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/send_to_quality_manager', { study_plan_id })
  },
  approveQualityManager(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/approve_quality_manager', { study_plan_id })
  },
  sendToGit(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/send_to_git', { study_plan_id })
  },
  approveGit(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/approve_git', { study_plan_id })
  },
  sendToStudyDirector(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/send_to_study_director', { study_plan_id })
  },
  approveStudyDirector(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/approve_study_director', { study_plan_id })
  },
  sendToExecutionTeam(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/send_to_execution_team', { study_plan_id })
  },
  start(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/start', { study_plan_id })
  },
  finish(study_plan_id: number | null): Promise<void> {
    return post('/api/study_plan/finish', { study_plan_id })
  },
  getHistory(study_plan_id: number | null): Promise<Array<IHistory>> {
    return get('/api/study_plan/history', { study_plan_id })
  },
  saveStudyPlanHistory(data: ISaveHistory): Promise<void> {
    return post('/api/study_plan/history/save', { ...data })
  },
  getTransitions(study_plan_id: number | null): Promise<Array<ITransition>> {
    return get('/api/study_plan/transitions', { study_plan_id })
  },
  uploadStudyPerformerFile(study_plan_id: number | null, file: File | null): Promise<Array<IStudyPerformer>> {
    return postFormData('/api/study_plan/study_performer/upload', { study_plan_id, file })
  },
  addStudyPerformer(data: IAddStudyPerformer): Promise<Array<IStudyPerformer>> {
    return post('/api/study_plan/study_performer/save', { ...data })
  },
  removeStudyPerformer(study_performer_id: number | null): Promise<Array<IStudyPerformer>> {
    return post('/api/study_plan/study_performer/delete', { study_performer_id })
  },
  addControlledCondition(data: IAddControlledCondition): Promise<Array<IControlledCondition>> {
    return post('/api/study_plan/controlled_condition/save', { ...data })
  },
  removeControlledCondition(controlled_condition_id: number | null): Promise<Array<IControlledCondition>> {
    return post('/api/study_plan/controlled_condition/delete', { controlled_condition_id })
  },
  getPops(study_plan_id: number | null): Promise<Array<IPop>> {
    return get('/api/study_plan/pops', { study_plan_id })
  },
  addPop(data: IAddPop): Promise<IPopResponse> {
    return post('/api/study_plan/pop/add', { ...data })
  },
  removePop(study_plan_id: number | null, study_plan_pop_id: number | null): Promise<IPopResponse> {
    return post('/api/study_plan/pop/delete', { study_plan_id, study_plan_pop_id })
  },
  savePop(data: ISavePop): Promise<Array<IPop>> {
    return post('/api/study_plan/pop/save', { ...data })
  },
  addReferenceItem(data: IAddReferenceItem): Promise<Array<IReferenceItem>> {
    return post('/api/study_plan/reference_item/save', { ...data })
  },
  removeReferenceItem(reference_item_id: number | null): Promise<Array<IReferenceItem>> {
    return post('/api/study_plan/reference_item/delete', { reference_item_id })
  },
  getSamples(): Promise<Array<ISample>> {
    return get('/api/study_plan/samples')
  },
  saveSample(data: ISample): Promise<ISampleResponse> {
    return post('/api/study_plan/sample/save', { ...data })
  },
  addSample(data: IAddSample): Promise<ISampleResponse> {
    return post('/api/study_plan/sample/add', { ...data })
  },
  removeSample(study_plan_id: number | null, sample_id: number | null): Promise<ISampleResponse> {
    return post('/api/study_plan/sample/delete', { study_plan_id, sample_id })
  },
  getCustomersPdf(study_plan_id: number | null): Promise<Array<ICustomerPdf>> {
    return get('/api/study_plan/customers/pdf', { study_plan_id })
  },
  getStudies(study_plan_id: number | null): Promise<IStudyResponse> {
    return get('/api/study_plan/studies', { study_plan_id })
  },
  finishStudy(study_id: number | null, type: EnumStudyType): Promise<void> {
    return post('/api/study_plan/study/finish', { study_id, type })
  },
  previewStudy(study_id: number | null, type: EnumStudyType): Promise<void> {
    return post('/api/study_plan/study/preview', { study_id, type })
  },
  screeningStudy(study_id: number | null, type: EnumStudyType): Promise<void> {
    return post('/api/study_plan/study/screen', { study_id, type })
  },
  suspendStudy(study_id: number | null, type: EnumStudyType): Promise<void> {
    return post('/api/study_plan/study/suspend', { study_id, type })
  },
  editCosmeticForm(data: IEditCosmeticForm): Promise<Array<ISample>> {
    return post('/api/study_plan/sample/cosmetic_form/edit', data)
  },
}
