import type {
  IAddSample,
  IBankAccount,
  IFilterParticipants,
  IParamsFilterPaymentRequest,
  IParticipantGift,
  IParticipantTransport,
  IPaymentRequest,
  IPaymentRequestPaginated,
  IPixKeyInfo,
  ISample,
  IStudyPlan,
} from '@/interfaces/finance'
import type { IHistory } from '@/interfaces/history'
import type { ITransition } from '@/interfaces/transition'
import { get, post, postFormData } from './ajaxutils'

export default {
  getPayments(params: IParamsFilterPaymentRequest): Promise<IPaymentRequestPaginated> {
    return get('/api/payments', { params: JSON.stringify(params) })
  },
  getParticipantsGiftList(filters: IFilterParticipants): Promise<Array<IParticipantGift>> {
    return get('/api/payment/participants/gift', { filters: JSON.stringify(filters) })
  },
  getParticipantsTransportList(filters: IFilterParticipants): Promise<Array<IParticipantTransport>> {
    return get('/api/payment/participants/transport', { filters: JSON.stringify(filters) })
  },
  getStudyPlans(): Promise<Array<IStudyPlan>> {
    return get('/api/payment/study_plans')
  },
  getSamples(payment_request_id: number | null): Promise<Array<ISample>> {
    return get('/api/payment/samples', { payment_request_id })
  },
  addSamples(data: IAddSample): Promise<Array<ISample>> {
    return post('/api/payment/sample/add', { ...data })
  },
  removeSample(payment_request_id: number | null, sample_id: number | null): Promise<Array<ISample>> {
    return post('/api/payment/sample/delete', { payment_request_id, sample_id })
  },
  getTransitions(payment_request_id: number | null): Promise<Array<ITransition>> {
    return get('/api/payment/transitions', { payment_request_id })
  },
  getHistory(payment_request_id: number): Promise<Array<IHistory>> {
    return get('/api/payment/history', { payment_request_id })
  },
  updateStatus(payment_request_id: number | null): Promise<void> {
    return post('/api/payment/update', { payment_request_id })
  },
  cancel(payment_request_id: number | null): Promise<void> {
    return post('/api/payment/cancel', { payment_request_id })
  },
  pay(payment_request_id: number | null): Promise<void> {
    return post('/api/payment/pay', { payment_request_id })
  },
  save(data: IPaymentRequest, file: File | null): Promise<IPaymentRequest> {
    return postFormData('/api/payment/save', { ...data, file })
  },
  saveBankAccount(participant_recruitment_id: number | null, bank_account: IBankAccount): Promise<IBankAccount> {
    return post('/api/participant/bank_account/save', { participant_recruitment_id, bank_account })
  },
  getPixKeyInfo(key: string): Promise<IPixKeyInfo> {
    return get('/api/payment/get_pix_key_info', { key })
  },
  markPaidAnotherStudy(schedule_id: number | null, reason: string): Promise<void> {
    return post('/api/payment/transport/pay_another_study', { schedule_id, reason })
  },
}
